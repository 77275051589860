<template>
  <v-app>
    <!-- app header -->
    <app-header></app-header>
    <pd-header></pd-header>


    <!-- left panel system admin-->
    <left-panel v-if="$route.meta.showLeftPanel"></left-panel>

    <!-- content -->
    <v-main class="bg">
      <router-view :key="$route.path"></router-view>
    </v-main>

    <!-- app footer -->
    <app-footer></app-footer>
    <pd-footer></pd-footer>
  </v-app>
</template>

<script>
import AppHeader from "@/components/Header";
import PDHeader from "@/components/PDHeader";
import AppFooter from "@/components/AppFooter";
import PDFooter from "@/components/PDFooter";
import LeftPanelSysAdmin from "@/components/SysAdmin/LeftPanelSysAdmin";
export default {
  name: "App",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
    "left-panel": LeftPanelSysAdmin,
    "pd-header": PDHeader,
    "pd-footer": PDFooter,


  },

  data: () => ({}),

  methods: {},
  watch: {},
};
</script>

<style lang="scss">
@import "~scss/main";

.bg{
  background-image: url("~@/assets/plain.jpg") !important;
  background-size: cover !important;
  background-position: 50% 80%;
  overflow-y: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
