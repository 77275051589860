import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#004892',
                buttonclr: '#8ec33c',
                success: '#52BE80',
                error: '#ff3737',
                text: '#025c83',
                button: '#3498DB',
                excel: '#217346',
                pdf: '#b70f0a',
                csv: '#f0ad4e'
            },
            dark: {},
        },
    },
    icons: {
        iconfont: 'mdi',
    },

});
