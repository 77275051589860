<template>

  <v-footer id="pd-footer" padless v-if="$route.meta.showPDFooter && $vuetify.breakpoint.lgAndUp"
    class="app-footer-color">

    <div class="marquee marquee-footer-color">
      <!-- <span> {{ displayString }} {{ displayString}} {{ displayString}}  {{ displayString}} {displayString} </span>  -->
      <div>
        <img class="logo-style" src="../assets/logo-header.png" height="25px">
        <span class="pl-15" id="displayText">{{displayText}}</span>
        <span id="displayText2">{{displayText2}}</span>
        <span id="displayText3">{{displayText3}}</span>
      </div>
    </div>

    <p class="datetime-footer-color">
      <span id="datetime"> {{displayDatetime}} </span>
    </p>

    <div class="footer-company">Powered by Scienotech Sdn. Bhd</div>

  </v-footer>

</template>

<script>

  // Axios for fetching data
  import axios from "axios";


  export default {

    data: () => ({

      displayText: "",
      displayText2: "",
      displayText3: "",
      displayDatetime: "",

      stationDetails: [],
      exceedanceDetails: [],
      parameterList: ["PH", "DO_SAT", "TSS"],
      parameterFullName: ["pH", "Dissolved Oxygen (Saturation)", "Total Suspended Solids"],
      dataToDisplay: "",
      displayString: "",
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

    }),

    mounted() {
      console.log("welcome home");
      this.getStationInfo();
    },

    methods: {


      getStationInfo() {
        axios
          .get(this.globalUrl + "/bakaj/readingpublic", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.stationDetails = response.data;

            this.getExceedanceInfo();

          })
          .catch((error) => {
            console.log(error);
          });
      },

      getExceedanceInfo() {
        axios
          .get(this.globalUrl + "/bakaj/exceedancevalpublic", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.exceedanceDetails = response.data;

            this.getExceedanceValue();

          })
          .catch((error) => {
            console.log(error);
          });
      },

      getExceedanceValue() {

        this.displayString = " Welcome to BAKAJ Water Quality System "
        let tempStr = ""

        for (let i = 0; i < this.exceedanceDetails.length; i++) {
          for (let j = 0; j < this.stationDetails.length; j++) {
            for (let k in this.parameterList) {

              if (this.exceedanceDetails[i].STATION_ID == this.stationDetails[j].STATION_ID_A)

                if (this.exceedanceDetails[i][this.parameterList[k]] !== null) {


                  tempStr = ""
                  if (this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] == "I") {
                    tempStr = this.stationDetails[j].STATION_ID_A + " - " + this.stationDetails[j].LOCATION + " - " + this.parameterFullName[k] + " - <span style = 'color: #051DF6' > CLASS " + this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] + '</span>'
                  }

                  else if (this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] == "II") {
                    tempStr = this.stationDetails[j].STATION_ID_A + " - " + this.stationDetails[j].LOCATION + " - " + this.parameterFullName[k] + " - <span style = 'color: #05F61D' > CLASS " + this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] + '</span>'
                  }

                  else if (this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] == "III") {
                    tempStr = this.stationDetails[j].STATION_ID_A + " - " + this.stationDetails[j].LOCATION + " - " + this.parameterFullName[k] + " - <span style = 'color: #F5E700' > CLASS " + this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] + '</span>'
                  }

                  else if (this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] == "IV") {
                    tempStr = this.stationDetails[j].STATION_ID_A + " - " + this.stationDetails[j].LOCATION + " - " + this.parameterFullName[k] + " - <span style = 'color: #F6C105' > CLASS " + this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] + '</span>'
                  }

                  else if (this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] == "V") {
                    tempStr = this.stationDetails[j].STATION_ID_A + " - " + this.stationDetails[j].LOCATION + " - " + this.parameterFullName[k] + " - <span style = 'color: #F60505' > CLASS " + this.stationDetails[j]["PARAM_" + this.parameterList[k] + "_CLASS"] + '</span>'
                  }


                  this.displayString = this.displayString + " ● " + tempStr




                }

            }

          }

        }



        // if (document.getElementById("displayText") != null) {
        //   // document.getElementById("displayText").innerHTML = this.displayString + " ● Any inquiries, please contact 0123456789 or email to bakaj_wqms@scienotech.com.my " 

        //   // document.getElementById("displayText2").innerHTML = " ● " + this.displayString + " ● Any inquiries, please contact 0123456789 or email to bakaj_wqms@scienotech.com.my " 

        //   // document.getElementById("displayText3").innerHTML = " ● " + this.displayString + " ● Any inquiries, please contact 0123456789 or email to bakaj_wqms@scienotech.com.my "  ;

        //   console.log("str " , this.displayString, this.exceedanceDetails)
        // }

        this.displayText = this.displayString + " ● Any inquiries, please contact 07-266 1201 or email to bakaj@johor.gov.my "

        this.displayText2 = " ● " + this.displayString + " ● Any inquiries, please contact 07-266 1201 or email to bakaj@johor.gov.my "

        this.displayText3 = " ● " + this.displayString + " ● Any inquiries, please contact 07-266 1201 or email to bakaj@johor.gov.my ";





        let d = new Date();

        let disp_time = d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

        let disp_date = d.getDate() + " " + this.months[d.getMonth()] + " " + d.getFullYear();

        // if (document.getElementById("datetime") != null) {
        //   document.getElementById("datetime").innerHTML = disp_time + " | " + disp_date;
        // }

        this.displayDatetime = disp_time + " | " + disp_date;


        setTimeout(() => {
          this.getStationInfo();
        }
          , 39000);
      }





    },
  }
</script>

<style lang="scss" scoped>
  .app-footer-color {
    // background-image: linear-gradient(to left,#004892, #0C64BF) !important; 
    background-color: transparent;
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 10px;
    z-index: 9999999 !important;
  }

  .marquee-footer-color {
    // background-image: linear-gradient(to left,#004892, #0C64BF) !important; 
    background-color: white;
    position: absolute;
    width: 90% !important;

  }

  .datetime-footer-color {
    background-image: linear-gradient(to left, #012d57, #012d57) !important;
    color: white;
    position: absolute;
    // width:11% !important;
    padding: 5px;
    font-size: 15px;
    left: 90%;
    display: inline-block;
    margin: 0 auto;
    border: black 2px solid;

  }

  .logo {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    // left: 20px;
    right: 800px;
    // text-align: center;
    top: 0px;
  }

  .footerlink:hover {
    //font-size: 18px;
    color: orange !important;
  }


  #scroll-container {
    // border: 3px solid black;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
  }

  #scroll-text {
    text-align: right;

    /* animation properties */
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);

    -moz-animation: my-animation 1s linear infinite;
    -webkit-animation: my-animation 30s linear infinite;
    animation: my-animation 30s linear infinite;
  }

  /* for Firefox */
  @-moz-keyframes my-animation {
    from {
      -moz-transform: translateX(-100%);
    }

    to {
      -moz-transform: translateX(100%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes my-animation {
    from {
      -webkit-transform: translateX(-100%);
    }

    to {
      -webkit-transform: translateX(100%);
    }
  }

  @keyframes my-animation {
    from {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }

    to {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }


  /////
  /// 
  /// 
  .marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  .marquee div {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 30s linear infinite;
    font-size: 18px !important;
    font-weight: bold;
  }

  .marquee2 div {
    animation-delay: -2.5s;
  }

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(-100%, 0);
    }
  }



  .class-text-I {
    color: #051DF6 !important;
  }

  .class-text-II {
    color: #05F61D !important;
  }

  .class-text-III {
    color: #F5E700 !important;
    background: blue !important;
    font-weight: bold !important;
  }

  .class-text-IV {
    color: #F6C105 !important;
  }

  .class-text-V {
    color: #F60505 !important;
  }

  .class-text-NA {
    color: #cccccc !important;
  }

  .logo-style {
    position: absolute;
  }

  .footer-company {
    color: white;
    font-weight: 200px;
    font-size: 12px;
    margin: auto;
    bottom: -28px;
    position: relative;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
</style>