<template>
  <v-container >

    <v-navigation-drawer clipped width="auto" dense app v-show = "$vuetify.breakpoint.mdAndUp ? true : false" class="left-panel-subheader">
      <v-list-item class="py-3 left-panel-header">
        <v-list-item-content>
          <v-list-item-title  class="text-h6 white--text"><h3><v-icon class="mr-2 mb-1 white--text">mdi-microsoft-xbox-controller-menu</v-icon> SYSTEM ADMIN</h3> </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense >
        <v-list-group v-for="elem in panelList"  :value = "elem.name == 'User Management' ? true : false " :prepend-icon="elem.icon" no-action class="py-1"  :key="elem.name" >
          <template v-slot:activator>
            <v-list-item-content>                  
                  <v-list-item-title  > {{elem.name}} </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item 
                v-for="item in elem.item"
                :key="item.name"
                link
                :to="item.url"
          > 
                <v-list-item-icon v-if = "item.name == 'Inbox'">
                    <v-badge
                      color="red"
                      :content= "countList"
                      :value= "countList"
                      offset-y="23"
                      overlap
                    >
                    <v-icon class="text--text" v-text="item.icon"></v-icon>
                    </v-badge>
                </v-list-item-icon>


                <v-list-item-icon v-else >
                    <v-icon class="text--text" v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-start text--text a-0 ma-0 font-weight-bold" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-btn
    color = "primary"
    @click="sheet = !sheet"
    class = "btn-menu"
    fab
    v-show = "$vuetify.breakpoint.mdAndUp ? false : true"
    >
        <v-icon color="text--white">
          mdi-menu
        </v-icon>
    </v-btn>

    <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center left-panel-mobile"
        scrollable
      >
        <v-btn
          class="mt-2 align-center"
          color="primary" small
          text
          @click="sheet = !sheet"

        >
          <v-icon>mdi-chevron-double-down</v-icon>
        </v-btn>

        <v-container fluid>

          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="elem in panelList"
              :key="elem.name"
              v-model="elem.active"
            >
              <v-expansion-panel-header > 
                    <v-card-text v-if = "elem.name == 'Request Data (API)'" class="text--text pa-0 ma-0 font-weight-bold">
                    <v-icon class="mr-2 text--text">{{ elem.icon }}</v-icon>  
                      <v-badge
                        color="red"
                        :content= "countList"
                        :value= "countList"
                        offset-x="0"
                        offset-y="5"
                      > 
                        {{ elem.name }} 
                      </v-badge>
                    </v-card-text>

                    <v-card-text v-else class="text--text pa-0 ma-0 font-weight-bold">
                      <v-icon class="mr-2 text--text">{{ elem.icon }}</v-icon>  {{ elem.name }}
                    </v-card-text>
              </v-expansion-panel-header>
              
              <v-expansion-panel-content>
                <v-list-item
                v-for="item in elem.item"
                :key="item.name"
                link
                :to="item.url"
                @click="sheet = !sheet"
              >

                  <v-list-item-icon>

                    <v-badge
                          color="red"
                          :content= "countList"
                          :value= "countList"
                          offset-y="23"
                          overlap
                          v-if = "item.name == 'Inbox'"
                        >
                        <v-icon class="text--text" v-text="item.icon"></v-icon>
                    </v-badge>


                    <v-icon v-else class="text--text" v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-start text--text pa-0 ma-0 font-weight-bold" v-text="item.name"></v-list-item-title>
              </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-container>
      </v-sheet>
    </v-bottom-sheet>

  </v-container>
</template>





<script>

import axios from "axios";
import appMixins from '@/mixins/globalMixins'


export default {
  
  data: () => ({

    sheet: false,
    countList: 0,
    drawer: false,

    panelList : [
      {
        name: "User Management",
        icon: "mdi-account",
        item : [
          { 
            name: "User List", 
            icon: "mdi-format-list-bulleted", 
            url: '/systemAdmin/userManagement/userList' 
          },

          { 
            name: "Add User",
            icon: "mdi-account-plus", 
            url:  '/systemAdmin/userManagement/addUser' 
          }
        ]
      },
      {
        name: "Station Management",
        icon: "mdi-store",
        item : [
            { 
              name: "Station List", 
              icon: "mdi-format-list-bulleted", 
              url: '/systemAdmin/stationManagement/stationList' 
            },
            { 
              name: "Add Station", 
              icon: "mdi-store-24-hour", 
              url: '/systemAdmin/stationManagement/addStation' 
            }
        ]
      },
      {
        name: "Point Of Interest Management",
        icon: "mdi-map-marker",
        item: [
             { 
                name: "List of Point Interest", 
                icon: "mdi-format-list-bulleted", 
                url: '/systemAdmin/pointOfInterest/ListInterest' 
              },
              { 
                name: "Add Point Interest", 
                icon: "mdi-plus", 
                url: '/systemAdmin/pointOfInterest/addInterest' 
              }
        ]
      },
      {
        name: "Parameter Management",
        icon: "mdi-flask-outline",
        item: [
          { 
            name: "Parameter List", 
            icon: "mdi-format-list-bulleted", 
            url: '/systemAdmin/parameterManagement/parameterList' 
          },
          { 
            name: "Set Parameter", 
            icon: "mdi-cup-water", 
            url: '/systemAdmin/parameterManagement/addParameter' 
          },
          { 
            name: "Set Min Max", 
            icon: "mdi-tune", 
            url: '/systemAdmin/parameterManagement/setMinMax' 
          }
        ]
      },
      {
          name: "Manual Data Update",
          icon: "mdi-database-cog",
          item: [
                { 
                  name: "Data Update", 
                  icon: "mdi-database-refresh-outline", 
                  url: '/systemAdmin/manualDataUpdate/dataUpdate' 
                }
          ]
      },
      {
          name: "Maintenance Management",
          icon: "mdi-wrench-clock",
          item: [
            { 
              name: "Data Entry", 
              icon: "mdi-database-import-outline", 
              url: '/systemAdmin/maintenanceManagement/dataEntry' 
            },
            { 
              name: "Maintenance Record", 
              icon: "mdi-database-sync-outline", 
              url: '/systemAdmin/maintenanceManagement/OnGoing' 
            },
            { 
              name: "Historical Summary", 
              icon: "mdi-database-clock-outline", 
              url: '/systemAdmin/maintenanceManagement/Summary' 
            },
          ]
      },
      {
          name: "Calibration Management",
          icon: "mdi-air-filter",
          item: [
             { 
                name: "Data Entry", 
                icon: "mdi-database-import-outline", 
                url: '/systemAdmin/calibrationManagement/dataEntry' 
              },
              { 
                name: "Calibration Record", 
                icon: "mdi-database-sync-outline", 
                url: '/systemAdmin/calibrationManagement/OnGoing' 
              },
              { 
                name: "Historical Summary", 
                icon: "mdi-database-clock-outline", 
                url: '/systemAdmin/calibrationManagement/Summary' 
              },
          ]
      },
      // {
      //       name: "Audit Log",
      //       icon: "mdi-database-search",
      //       item: [
      //         { 
      //           name: "User Log", 
      //           icon: "mdi-account-eye", 
      //           url: '/systemAdmin/AuditLog/userLog' 
      //         },

      //         { 
      //           name: "Station Log",
      //           icon: "mdi-archive-eye", 
      //           url:  '/systemAdmin/AuditLog/stationLog' 
      //         },

      //         { 
      //           name: "API Data Sharing Log",
      //           icon: "mdi-file-eye", 
      //           url:  '/systemAdmin/AuditLog/apiLog' 
      //         },
      //       ]
      // }


    ],

  }),

  methods: {

  },

  computed: {

  },

  mounted() {
  },

  
};
</script>

<style lang="scss" scoped>

.btn-menu {
  position: fixed;
  top: 75px;
  z-index: 5;
  right: 7px;
}

.left-panel-mobile{
  background-image: url("~@/assets/sheet2.jpg") !important;
  background-size: cover !important;
  background-position: 50% 50% !important;
  overflow-y: hidden;
}

.left-panel-subheader{
  //background-image: url("~@/assets/leftpanel.jpg") !important;
    background-size: cover !important;
    background-position: 0% 100% !important;
    overflow-y: hidden;
}

.left-panel-header{
  // background-image: url("~@/assets/header2.jpg") !important;
  // background-color: #004892 !important;
  background-image: linear-gradient(to left,#0C64BF, #004892) !important; 
  background-size: cover !important;
  background-position: 100% 5% !important;
  overflow-y: hidden;
}



// }
</style>
