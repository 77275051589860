<template>

  <v-footer id="app-footer" padless v-if="$route.meta.showFooter && $vuetify.breakpoint.lgAndUp"
    class="app-footer-color">

    <div class="about_us">
      <v-bottom-sheet v-model="about_us" overlay-color="white" overlay-opacity="0.7" persistent>
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="green" dark v-bind="attrs" v-on="on">
            Open Persistent
          </v-btn>
        </template> -->
        <v-sheet class="text-center about-us-sheet" height="250px">
          <v-row>
            <v-col cols="11" class="pa-5 mt-10">
              <h2 class="text--primary text-start ml-7" style="color: #004892 !important;">About Us</h2>
            </v-col>
            <v-col cols="1">
              <v-btn text color="primary" @click="about_us = !about_us">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>

          </v-row>

          <v-card class="pa-5" style="background: none;" flat>
            <v-card-text>

              <v-row v-for="i in information[0].sub_item" :key=i.key>
                <v-col cols=6 class="text-h6 font-weight-bold align-center">
                  <div class="text-start">{{ i.title }}</div>
                </v-col>
                <v-col cols=6 class="text-start text-subtitle-1">
                  <span>{{ i.text }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <v-row class="justify-center align-center text-center" style="width: 80px !important">

      <v-col cols="2" sm="4" class="white--text pr-10"
        :style="$vuetify.breakpoint.mdAndDown? 'font-size: 12px;text-align:right;' : 'text-align:right;'">
        <span class="white--text footerlink" style="text-decoration: none;" @click="about_us = !about_us">About
          us</span> <br>
        <a href="" target="about_us" class="white--text footerlink" style="text-decoration: none;">Terms & Condition
        </a>
        <br>
        <a href="https://www.johor.gov.my/info-penting/dasar-privasi/" target="_blank" class="white--text footerlink"
          style="text-decoration: none;">Privacy Policy </a><br>
      </v-col>

      <v-col cols="2" sm="4" class="white--text px-0"
        :style="$vuetify.breakpoint.mdAndDown? 'font-size: 12px;text-align:center;' : 'text-align:center;'">
        For general enquiries, please contact <br>
        Operating Hours : <br>
        8:00 AM - 5:00 PM Daily <br>
        <v-icon color="white">mdi-email</v-icon> <a href=""
          class="orange--text footerlink">bakaj_wqms@scienotech.com.my</a>
      </v-col>

      <v-col cols="2" sm="4" class="white--text pl-10"
        :style="$vuetify.breakpoint.mdAndDown? 'font-size: 12px;text-align:left;' : 'text-align:left;'">
        Browser Compatibility
        <br>
        <v-icon color="white" class="ml-5">mdi-google-chrome</v-icon>
        <v-icon color="white" class="mx-5">mdi-firefox</v-icon>
        <v-icon color="white">mdi-microsoft-edge</v-icon>
      </v-col>

    </v-row>


    <div class="footer-company">Powered by Scienotech Sdn. Bhd.</div>

  </v-footer>

</template>

<script>
  export default {
    data: () => ({
      links: [
        {
          name: "Terms & Condition",
          link: ""
        },
        {
          name: "Privacy Policy",
          link: "https://www.johor.gov.my/info-penting/dasar-privasi/"
        },
        {
          name: "User Manual",
          link: ""
        },
      ],

      about_us: false,

      information: [
        {
          name: "About Us",
          sub_item: [
            { title: 'Who Are We', text: "Badan Kawalselia Air Negeri Johor (BAKAJ) manage and regulate raw water in Johor" },
            { title: 'The Objective of Water Quality Telemetric Installation', text: "To monitor the quality of current river water continuously and provide an early warning if contamination occurs, directly enabling relevant agencies to take control measures quickly" }
          ]
        }
      ],
    }),
  }
</script>

<style lang="scss" scoped>
  .app-footer-color {
    background-image: linear-gradient(to left, #004892, #0C64BF) !important;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .logo {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    // left: 20px;
    right: 800px;
    // text-align: center;
    top: 0px;
  }

  .footerlink:hover {
    //font-size: 18px;
    color: orange !important;
  }


  .footer-company {
    color: white;
    font-weight: 200px;
    font-size: 12px;
    margin: auto;
    bottom: -50px;
    right: 5px;
    position: relative;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }

  .about_us {
    height: 100% important;
    width: 100% !important;
  }

  .about-us-sheet {

    height: 250px;
    position: absolute;
    z-index: 999999;
    top: 0px;
    background: none
      /*  10% opaque green */
  }
</style>